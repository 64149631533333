<template>
  <tr
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)">
    <td class="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
      <div class="flex items-center">
        <router-link :to="{ name: 'product-show', params: { id: product.id }}" class="truncate hover:text-gray-600">
          <span>{{ product.name }}</span>
        </router-link>
      </div>
    </td>
    <td class="px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate block text-right">
      <span class="pr-1">{{ product.price }}</span>
    </td>
    <td class="hidden sm:table-cell px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate block text-right">
      <span class="">{{ product.unit }}</span>
    </td>
    <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
      <span>{{ product.vat && (product.vat * 100).toFixed(0) + ' %' }}</span>
    </td>
    <td class="hidden xl:table-cell px-7 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
      <span>{{ product.includes_vat ? $t('global.buttons.yes') : $t('global.buttons.no') }}</span>
    </td>
    <td class="pr-4">
      <EditActions
          v-if="showEditable || (selectedItem === String(product.id))"
          :item="product"
          itemType="product"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';

export default {
  name: 'Row',
  components: {
    EditActions
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    selectedItem: String
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(productId) {
      this.$emit('handleOpenDeleteItemModal', productId);
    }
  }
}
</script>

<style scoped>

</style>
