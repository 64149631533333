<template>
  <tbody class="bg-white divide-y divide-gray-100">
    <Row
      v-for="product in dataTable"
      :key="product.id"
      :product="product"
      @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
      @handleSetSelectedItem="handleSetSelectedItem"
      :selectedItem="String(selectedItem)"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import Row from '@/components/Product/Table/TableBody/Row/Row';

export default {
  name: 'TableBody',
  components: {
    Row,
    EditActions
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  methods: {
    handleSetSelectedItem(productId) {
      this.selectedItem = productId;
    }
  }
}
</script>

<style scoped>

</style>
