<template>
  <div>
    <div class="h-full border-t border-b border-gray-200">
      <div class="md:grid md:grid-cols-1 md:gap-6">
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit="handleSubmit" ref="form">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <FormInput
                      :label="$t('product.fields.name')"
                      inputName="name"
                      :value="form.name"
                      :errorMessage="formErrors.name"
                      @handleInputChange="handleInputChange"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <FormInput
                      :label="$t('product.fields.id')"
                      inputName="invoicing_product_id"
                      :value="form.invoicing_product_id"
                      :errorMessage="formErrors.invoicing_product_id"
                      @handleInputChange="handleInputChange"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <FormInput
                      :label="$t('product.fields.titlePrice')"
                      type="number"
                      inputName="price"
                      :value="form.price"
                      :errorMessage="formErrors.price"
                      @handleInputChange="handleInputChange"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <FormInput
                      :label="$t('product.fields.unit')"
                      inputName="unit"
                      :value="form.unit"
                      :errorMessage="formErrors.unit"
                      @handleInputChange="handleInputChange"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <VatOptions
                      :selectedVat="selectedVat"
                      :editable="true"
                      @selectVat="selectVat"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <VatIncludes
                      :editable="true"
                      :includesVat="form.includes_vat"
                      @selectVatIncludes="selectVatIncludes"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vatOptions from '../../../helpers/vatOptions';
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
import VatOptions from '../../../components/SelectOptions/VatOptions/VatOptions';
import VatIncludes from '../../../components/SelectOptions/VatIncludes/VatIncludes';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'CreateOrUpdate',
  components: {
    VatIncludes,
    VatOptions,
    FormInput
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      vatOptions: vatOptions,
      selectedVat: vatOptions[0],
      pageUpdateAction: false,
      loading: false,
      id: '',
      form: {
        name: '',
        unit: '',
        price: '',
        invoicing_product_id: '',
        vat: vatOptions[0].value,
        includes_vat: false
      },
      formValidation: true,
      formErrors: {
        name: [],
        unit: [],
        price: [],
        invoicing_product_id: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'product',
      'productResponseMessage',
      'createProductSuccess',
      'createProductError',
      'updateProductSuccess',
      'updateProductError'
    ])
  },
  watch: {
    createProductSuccess() {
      this.$router.push({ name: 'product-show', params: { id: this.product.id } });
    },
    createProductError(newValue) {
      this.handleRequestErrorMessage(newValue);
    },
    updateProductSuccess() {
      this.$router.push({ name: 'product-show', params: { id: this.product.id } });
    },
    updateProductError(newValue) {
      this.handleRequestErrorMessage(newValue);
    },
    product(data) {
      if (this.pageUpdateAction) {
        this.form = { ...data };
        this.selectedVat = vatOptions.find(item => item.value === data.vat);
        if (data) {
          this.loading = false;
          this.handleSetBreadCrumbItem(data.name);
        }
      }
    }
  },
  mounted() {
    this.handleSetBreadCrumbItems({ index: 0, to: '/products' });
    this.handleSetBreadCrumbItems({ index: 1, to: '' });
    const { id } = this.$route.params;
    if (id) {
      this.loading = true;
      this.handleGetProduct(id);
    }
    this.id = id;
    this.pageUpdateAction = !!id;
    EventBus.$on('handleSaveForm', () => this.handleSubmit());
  },
  methods: {
    ...mapActions({
      handleGetProduct: 'getProduct',
      handleCreateProduct: 'createProduct',
      handleUpdateProduct: 'updateProduct',
      handleSetBreadCrumbItem: 'setBreadCrumbItem',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    selectVat(vat) {
      this.form.vat = vat.value;
      this.selectedVat = vat;
    },
    selectVatIncludes(value) {
      this.form.includes_vat = value;
    },
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
      this.formValidation = true;
    },
    handleRequestErrorMessage(isError) {
      if (isError) {
        const { message, errors } = this.productResponseMessage;
        this.formErrors = { ...this.formErrors, ...errors };
        this.toastMessage(message, 'error');
      }
    },
    handleSubmit() {
      this.pageUpdateAction ? this.updateProduct() : this.createProduct();
    },
    createProduct() {
      this.handleCreateProduct({ form: this.form });
    },
    updateProduct() {
      this.handleUpdateProduct({ id: this.id, form: this.form });
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
    EventBus.$off('handleSaveForm');
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style scoped>

</style>
