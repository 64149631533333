<template>
  <thead class="border-t border-gray-200 border-b bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
    <tr>
      <th class="whitespace-nowrap w-3/7 px-6 py-3 ">
        <div class="flex items-end cursor-pointer" @click="handleSort('name')">
          <div class="relative flex items-center flex-start pr-6 font-medium">
            {{ $t('product.fields.title') }}
            <ArrowSolidIcon v-if="selectedSortingKey === 'name'" :arrowUp="!arrowSortUp['name']"/>
          </div>
        </div>
      </th>
      <th class="whitespace-nowrap py-3 w-100p">
        <div class="flex justify-end w-full cursor-pointer" @click="handleSort('price')">
          <div class="relative flex items-center flex-start pr-6 font-medium">
            {{ $t('product.fields.price') }} €
            <ArrowSolidIcon v-if="selectedSortingKey === 'price'" :arrowUp="!arrowSortUp['price']"/>
          </div>
        </div>
      </th>
      <th class="hidden sm:table-cell w-100p">
        <div class="flex justify-end cursor-pointer" @click="handleSort('unit')">
          <div class="relative flex items-center flex-start pr-6 font-medium">
            {{ $t('product.fields.unit') }}
            <ArrowSolidIcon v-if="selectedSortingKey === 'unit'" :arrowUp="!arrowSortUp['unit']"/>
          </div>
        </div>
      </th>
      <th class="hidden md:table-cell w-100p">
          <div class="flex justify-end cursor-pointer" @click="handleSort('vat')">
            <div class="relative flex items-center flex-start pr-6 font-medium">
              {{ $t('product.fields.vat') }}
              <ArrowSolidIcon v-if="selectedSortingKey === 'vat'" :arrowUp="!arrowSortUp['vat']"/>
            </div>
          </div>
        </th>
      <th class="whitespace-nowrap hidden xl:table-cell w-150p">
        <div class="flex justify-end w-full cursor-pointer" @click="handleSort('includes_vat')">
          <div class="relative flex items-center flex-start pr-6 font-medium">
            {{ $t('product.fields.vat_included') }}
            <ArrowSolidIcon v-if="selectedSortingKey === 'includes_vat'" :arrowUp="!arrowSortUp['includes_vat']"/>
          </div>
        </div>
      </th>
      <th class="w-40p" />
    </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHead',
  components: {
    ArrowSolidIcon
  },
  data() {
    return {
      arrowSortUp: {
        name: false,
        price: false,
        unit: false,
        vat: false,
        includes_vat: false
      },
      selectedSortingKey: 'name'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    }
  }
}
</script>

<style scoped>

</style>
