<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout.vue';

export default {
  name: 'Products',
  components: {
    AppLayout
  }
}
</script>

<style scoped>

</style>
