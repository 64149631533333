<template>
  <div>
    <dt class="text-sm font-medium text-gray-500">
      {{ $t('product.fields.vat') }}
    </dt>
    <div class="mt-1 relative" v-click-outside="closeDropdown">
      <button @click="isOpen = !isOpen"
              type="button"
              :class="[{'cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm': editable}]"
              :disabled="!editable"
      >
        <span class="block truncate text-sm">{{ selectedVat.name }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <FormInputArrowIcon v-if="editable" />
       </span>
      </button>
      <div class="absolute mt-1 w-full rounded-md bg-white z-100">
        <ul v-show="isOpen" class="max-h-60 rounded-md py-1 text-base border border-gray-300 overflow-auto focus:outline-none sm:text-sm">
          <li
              v-for="option in vatOptions"
              :key="option.id"
              @click="select(option)"
              :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                       'text-white bg-primary': selectedVat.id === option.id,
                       'text-gray-900 hover:bg-gray-50': selectedVat.id !== option.id
              }]"
              role="option">
            <span class="font-normal block truncate">{{ option.name }}</span>
            <span
                v-if="selectedVat.id === option.id"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="selectedVat.id === option.id ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import vatOptions from '@/helpers/vatOptions';

export default {
  name: 'VatOptions',
  components: { FormInputArrowIcon },
  directives: {
    ClickOutside
  },
  props: {
    selectedVat: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      vatOptions: vatOptions
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    select(value) {
      this.$emit('selectVat', value);
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style scoped>

</style>
