<template>
  <div>
    <DeleteItemModal
        v-if="showModal"
        :title="$t('product.deleteProductModal.title')"
        :description="$t('product.deleteProductModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteProduct"
    />
    <div class="relative" v-if="!loading">
      <DataTableSearch @handleSearchTable="handleSearchTable" />
       <Table
           @handleSort="handleSort"
           @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
           :dataTable="dataTable"
       />

      <Pagination :count="getProductCount" @handlePaginate="handlePaginate"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditActions from '../../../components/Table/EditActions/EditActions';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import handleTableSort from '@/helpers/handleTableSort';
import Pagination from '@/components/Main/Pagination/Pagination';
import TableHead from '@/components/Product/Table/TableHead/TableHead';
import TableBody from '@/components/Product/Table/TableBody/TableBody';
import Table from '@/components/Product/Table/Table';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'Products',
  components: {
    Table,
    TableBody,
    TableHead,
    DataTableSearch,
    DeleteItemModal,
    Pagination,
    EditActions
  },
  data() {
    return {
      count: 0,
      data: [],
      dataTable: [],
      selectedProductId: null,
      showModal: false,
      loading: true,
      searchValue: '',
      searchTimer: null,
      page: 1,
      sortBy: 'name',
      sortDesc: 0
    }
  },
  computed: {
    ...mapGetters([
        'products',
        'getProductCount'
    ])
  },
  watch: {
    products(newValue) {
      this.data = newValue;
    },
    data(newValue) {
      this.count = newValue.length;
      this.dataTable = newValue.slice(0, 30);
      this.loading = false;
    }
  },
  mounted() {
    this.handleGetProducts({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
  },
  methods: {
    ...mapActions({
      handleDeleteProduct: 'deleteProduct',
      handleGetProducts: 'getProducts'
    }),
    handleSort(key, sorting) {
      this.sortBy = key;
      this.sortDesc = !sorting;

      this.handleGetProducts({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
    },
    handleSearchTable(value) {
      this.searchValue = value;
      this.page = 1;
      EventBus.$emit('resetPage');

      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.handleGetProducts({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
      }, 500);
    },
    handlePaginate(page, defaultPageSlice) {
      this.page = defaultPageSlice / (defaultPageSlice - page);
      this.handleGetProducts({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
    },
    handleOpenDeleteItemModal(productId) {
      this.selectedProductId = productId;
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteProduct() {
      this.handleDeleteProduct({ id: this.selectedProductId })
          .then(response => this.toastMessage(response.message, 'success'))
          .catch(error => this.toastMessage(error.message, 'error'))
      this.showModal = false;
      this.data = this.data.filter(product => product.id !== this.selectedProductId);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  },
  destroyed() {
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style scoped>

</style>
