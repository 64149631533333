<template>
  <div class="h-ful border-t border-b">
    <div class="md:grid md:grid-cols-1 md:gap-6">
      <form v-on:submit.prevent="handleSubmit" ref="form">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('product.fields.name')" :name="form.name" />
                </div>
                <FormInput
                    v-if="editable"
                    :label="$t('product.fields.name')"
                    inputName="name"
                    :value="form.name"
                    :errorMessage="formErrors.name"
                    @handleInputChange="handleInputChange"
                />
            </div>
            <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('product.fields.id')" :name="form.invoicing_product_id" />
                </div>
                <FormInput
                    v-if="editable"
                    :label="$t('product.fields.id')"
                    inputName="invoicing_product_id"
                    :value="form.invoicing_product_id"
                    :errorMessage="formErrors.invoicing_product_id"
                    @handleInputChange="handleInputChange"
                />
            </div>
            <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('product.fields.titlePrice')" :name="form.price" />
                </div>
                <FormInput
                    v-if="editable"
                    :label="$t('product.fields.titlePrice')"
                    type="text"
                    inputName="price"
                    :value="form.price"
                    :errorMessage="formErrors.price"
                    @handleInputChange="handleInputChange"
                />
            </div>
            <div class="sm:col-span-1">
                <div v-if="!editable" class="fieldTitle">
                  <MainFieldTitle :label="$t('product.fields.unit')" :name="form.unit" />
                </div>
                <FormInput
                    v-if="editable"
                    :label="$t('product.fields.unit')"
                    inputName="unit"
                    :value="form.unit"
                    :errorMessage="formErrors.unit"
                    @handleInputChange="handleInputChange"
                />
            </div>
            <div class="sm:col-span-1">
              <VatOptions
                  :selectedVat="selectedVat"
                  :editable="editable"
                  @selectVat="selectVat"
              />
            </div>
            <div class="sm:col-span-1">
              <VatIncludes
                  :editable="editable"
                  :includesVat="form.includes_vat"
                  @selectVatIncludes="selectVatIncludes"
              />
              <div v-if="!editable">
                <MainFieldTitle :label="$t('product.fields.vat_included')" :name="form.includes_vat ? $t('global.buttons.yes') : $t('global.buttons.no')" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import vatOptions from '../../../helpers/vatOptions';
import ClickOutside from 'vue-click-outside';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import VatOptions from '../../../components/SelectOptions/VatOptions/VatOptions';
import VatIncludes from '../../../components/SelectOptions/VatIncludes/VatIncludes';
import { EventBus } from '@/eventBus/event-bus';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';

export default {
  name: 'Product',
  components: {
    MainFieldTitle,
    VatIncludes,
    VatOptions,
    ErrorMessage,
    FormInput
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      editable: false,
      vatOptions: vatOptions,
      selectedVat: vatOptions[0],
      loading: true,
      id: '',
      form: {
        id: '',
        name: '',
        unit: '',
        price: '',
        invoicing_product_id: '',
        vat: vatOptions[0].value,
        includes_vat: false
      },
      formValidation: true,
      formErrors: {}
    }
  },
  computed: {
    ...mapGetters([
      'product',
      'createProductSuccess',
      'updateProductSuccess',
      'updateProductError',
      'productResponseMessage'
    ])
  },
  watch: {
    createProductSuccess(newValue) {
      newValue && this.toastMessage(this.productResponseMessage, 'success');
    },
    updateProductSuccess(newValue) {
      if (newValue) {
        this.editable = false;
        this.handleSetBreadCrumbItem(this.form.name);
        EventBus.$emit('formSubmitted', true);
        this.toastMessage(this.productResponseMessage, 'success');
      }
    },
    updateProductError(newValue) {
      if (newValue) {
        const { message, errors } = this.productResponseMessage;
        this.formErrors = {
          ...this.formErrors,
          ...errors
        };
        this.toastMessage(message, 'error');
      }
    },
    product(data) {
      this.form = { ...data };
      this.selectedVat = vatOptions.find(item => item.value === data.vat);
      this.handleSetBreadCrumbItem(data.name);
      this.loading = false;
    }
  },
  mounted() {
    this.handleSetBreadCrumbItems({ index: 0, to: '/products' });
    const { id } = this.$route.params;
    id && this.handleGetProduct(id);
     EventBus.$on('handleEdit', editable => {
      this.editable = editable;
      this.form = { ...this.product };
      this.formErrors = {};
    });
    EventBus.$on('handleSave', () => this.handleSubmit());
    this.updateProductSuccess | this.createProductSuccess && this.toastMessage(this.productResponseMessage, 'success');
  },
  methods: {
    ...mapActions({
      handleGetProduct: 'getProduct',
      handleUpdateProduct: 'updateProduct',
      handleSetBreadCrumbItem: 'setBreadCrumbItem',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    selectVat(vat) {
      this.form.vat = vat.value;
      this.selectedVat = vat;
    },
    selectVatIncludes(value) {
      this.form.includes_vat = value;
    },
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
      this.formValidation = true;
    },
    async handleSubmit() {
      this.editable && await this.handleUpdateProduct({ form: this.form, id: this.form.id });
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
    EventBus.$off('handleSave');
    this.handleSetBreadCrumbItem();
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style scoped>

</style>

