<template>
  <div class="w-full" v-if="editable">
    <dt class="text-sm font-medium text-gray-500">
      {{ $t('product.fields.vat_included') }}
    </dt>
    <div class="mt-1 relative" v-click-outside="closeDropdown">
      <button @click="isOpen = !isOpen" type="button"
              class="cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none sm:text-sm"
              :disabled="!editable"
      >
        <span class="block truncate">{{ selectedVatIncluded ? $t('global.buttons.yes') : $t('global.buttons.no') }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <FormInputArrowIcon v-if="editable" />
       </span>
      </button>
      <div class="absolute mt-1 w-full rounded-md bg-white z-100">
        <ul v-show="isOpen"
            class="max-h-60 rounded-md py-1 text-base border border-gray-300 overflow-auto focus:outline-none sm:text-sm">
          <li @click="handleSelect(true)" role="option"
              :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                      'text-white bg-primary': selectedVatIncluded,
                      'text-gray-900 hover:bg-gray-50': !selectedVatIncluded
              }]"
          >
            <span class="font-normal block truncate">{{ $t('global.buttons.yes') }}</span>
            <span
                v-if="selectedVatIncluded"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="selectedVatIncluded ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
          </li>
          <li @click="handleSelect(false)" role="option"
              :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                      'text-white bg-primary ': !selectedVatIncluded,
                      'text-gray-900 hover:bg-gray-50': selectedVatIncluded
              }]"
          >
            <span class="font-normal block truncate">{{ $t('global.buttons.no') }}</span>
            <span
                v-if="!selectedVatIncluded"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="!selectedVatIncluded ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';

export default {
name: 'VatIncludes',
  components: { FormInputArrowIcon },
  directives: {
    ClickOutside
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    includesVat: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedVatIncluded: false,
      isOpen: false
    }
  },
  watch: {
    includesVat(value) {
      this.selectedVatIncluded = value;
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleSelect(value) {
      this.selectedVatIncluded = value;
      this.isOpen = false;
      this.$emit('selectVatIncludes', value);
    }
  }
}
</script>

<style scoped>

</style>
