export default [
    {
        id: 1,
        name: '24 %',
        value: 0.24
    },

    {
        id: 2,
        name: '14 %',
        value: 0.14
    },

    {
        id: 3,
        name: '10 %',
        value: 0.1
    },

    {
        id: 4,
        name: '0 %',
        value: 0
    }
]
